import useWindowDimensions from "../../hooks/useWindowDimensions";
import ContactModal from "./components/ContactModal";
import LandingLargeWindow from "./components/LandingLargeWindow";
import LandingMediumWindow from "./components/LandingMediumWindow";
import LandingMobileWindow from "./components/LandingMobileWindow";
import LandingTabletWindow from "./components/LandingTabletWindow";
import "./landing.css";

const Landing = () => {
  const { width } = useWindowDimensions();
  let comp = null;
  if (width >= 1700) {
    comp = <LandingLargeWindow />;
  } else if (width >= 1400) {
    comp = <LandingMediumWindow />;
  } else if (width >= 1200) {
    comp = <LandingTabletWindow />;
  } else {
    comp = <LandingMobileWindow />;
  }

  return (
    <>
      {comp}
      <ContactModal />
    </>
  );
};

export default Landing;
