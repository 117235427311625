import {
  MDBAlert,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
} from "mdbreact";
import React, { Component, createRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { Modal } from "reactstrap";
import * as feedbackActions from "../../../redux/feedback/actions";

class ContactModal extends Component {
  constructor(props) {
    super(props);
    this.recaptchaRef = createRef();
  }

  state = {
    name: "",
    mobileNumber: "",
    message: "",
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toggleModal = () => {
    this.props.feedback.ui.showContactModal === true
      ? this.props.hideModal()
      : this.props.showModal();
  };

  contact = async () => {
    const { name, mobileNumber, message } = this.state;
    const captchaToken = await this.recaptchaRef.current.executeAsync();
    this.props.requestContact(name, mobileNumber, message, captchaToken);
  };

  render() {
    return (
      <Modal
        centered
        size="md"
        isOpen={this.props.feedback.ui.showContactModal}
        toggle={this.toggleModal}>
        <MDBModalBody className="text-center">
          <MDBIcon fas icon="phone" size="4x" className="text-primary animated rotateIn mb-3" />
          <p className="font-weight-bold mb-3 text-muted">Sizinle iletişime geçelim</p>
          <MDBInput
            group
            name="name"
            label="Adınız ve Soyadınız"
            valueDefault={this.state.firstName}
            onChange={this.handleInputChange}
          />
          <MDBInput
            group
            name="mobileNumber"
            label="Telefon Numaranız"
            valueDefault={this.state.mobileNumber}
            onChange={this.handleInputChange}
          />
          <MDBInput
            group
            name="message"
            label="Mesajınız"
            type="textarea"
            valueDefault={this.state.message}
            onChange={this.handleInputChange}
          />

          <ReCAPTCHA
            ref={this.recaptchaRef}
            size="invisible"
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
            hl="tr"
            badge="bottomleft"
          />
        </MDBModalBody>
        <MDBModalFooter>
          {this.props.feedback.contact.loading === true ? (
            <div className="text-center w-100">
              <MDBSpinner />
            </div>
          ) : (
            <div>
              <MDBBtn color="primary" outline onClick={this.toggleModal}>
                Çıkış
              </MDBBtn>
              <MDBBtn color="primary" onClick={this.contact}>
                Gönder
                <MDBIcon icon="paper-plane" className="ml-1" />
              </MDBBtn>
            </div>
          )}
          {this.props.feedback.contact.error && (
            <div style={{ width: "100%" }}>
              <MDBAlert color="danger" className="mb-1">
                {this.props.feedback.contact.error}
              </MDBAlert>
            </div>
          )}
        </MDBModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    feedback: state.feedback,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showModal: () => dispatch(feedbackActions.showContactModal()),
    hideModal: () => dispatch(feedbackActions.hideContactModal()),
    requestContact: (name, mobileNumber, message, captchaToken) => {
      dispatch(feedbackActions.requestContact(name, mobileNumber, message, captchaToken));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactModal);
